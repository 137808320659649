<template>
  <div>
    <div v-if="isLoading" class="position-absolute" style="left: 40%">
      <CSpinner color="info" class="align-self-center ml-5" />
    </div>
    <div v-else class="login-container">
      <div>
        <img src="/img/ForvisMazars-Logo.png" alt="Mazars" class="mz-logo" />
      </div>
      <CCard class="p-4 custom-card" v-if="show_email_login || !IS_MZ_BRANDING">
        <CCardBody>
          <CForm @submit.prevent="handleLogin">
            <h1 class="form-heading">
              {{ $t("Log in") }}
            </h1>
            <p class="form-subtext">
              {{ $t("Sign In to your account") }}
            </p>

            <CFormLabel class="form-label">Email*</CFormLabel>
            <CInput
              data-testid="emailInput"
              :placeholder="$t('Username')"
              autocomplete="username email"
              v-model="login"
              name="login"
              class="mb-3 mt-2"
              required
            />
            <CFormLabel class="form-label">Password*</CFormLabel>
            <CInput
              data-testid="passwordInput"
              :placeholder="$t('Password')"
              type="password"
              autocomplete="current-password"
              v-model="password"
              name="password"
              class="mb-3 mt-2"
              required
            />
            <CRow class="mt-2">
              <CCol class="text-left d-flex justify-content-between">
                <div class="d-flex">
                  <input
                    class="styled-checkbox"
                    id="styled-checkbox-1"
                    type="checkbox"
                    value="value1"
                  />
                  <p class="form-subtext ml-2 mt-2">Remember me</p>
                </div>
                <div class="text-right">
                  <CButton
                    color="link"
                    class="px-0"
                    data-cy="forgot-password-link"
                    @click="showForgotPassword = true"
                  >
                    {{ $t("Forgot password?") }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <div class="btn_container">
              <CButton
                data-testid="loginButton"
                color="primary"
                class="form-button"
                type="submit"
                data-cy="login-button"
                :disabled="isLogin"
              >
                {{ $t("Login") }}
              </CButton>
            </div>

            <div class="text-right">
              <CButton
                color="link"
                class="px-0 mt-3"
                @click="showFirstTimeLogin = true"
              >
                {{ $t("Login for the first time?") }}
              </CButton>
            </div>
          </CForm>
        </CCardBody>
        <CCardBody v-if="showFirstTimeLogin" class="slider">
          <div style="padding: 27px">
            <h1 class="form-heading">
              {{ $t("Login for the first time") }}
            </h1>
            <div style="margin-top: 45px">
              <CFormLabel class="form-label">Email</CFormLabel>
              <CInput
                :placeholder="$t('Email')"
                autocomplete="email"
                v-model="email"
                name="login"
                class="mb-3 mt-2"
                required
              />
              <div class="btn_container">
                <CButton
                  color="primary"
                  class="form-button"
                  type="submit"
                  @click="handleSubmit('create')"
                  style="position: absolute; bottom: 91px"
                >
                  {{ $t("Submit") }}
                </CButton>
              </div>
              <div>
                <CButton
                  color="link"
                  @click="showFirstTimeLogin = false"
                  class="back-btn"
                >
                  {{ $t("Back to login") }}
                </CButton>
              </div>
            </div>
          </div>
        </CCardBody>
        <CCardBody v-if="showForgotPassword" class="slider">
          <div style="padding: 27px">
            <h1 class="form-heading">
              {{ $t("Forgot Password") }}
            </h1>
            <div style="margin-top: 45px">
              <CFormLabel class="form-label">Email</CFormLabel>
              <CInput
                :placeholder="$t('Email')"
                autocomplete="email"
                v-model="email"
                name="login"
                class="mb-3 mt-2"
                required
              />
              <div class="btn_container">
                <CButton
                  color="primary"
                  class="form-button"
                  type="submit"
                  @click="handleSubmit('reset')"
                  style="position: absolute; bottom: 91px"
                  :disabled="isSendingResetRequest"
                >
                  {{ $t("Reset password") }}
                </CButton>
              </div>
              <div>
                <CButton
                  color="link"
                  @click="showForgotPassword = false"
                  class="back-btn"
                >
                  {{ $t("Back to login") }}
                </CButton>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CCard
        v-else
        color="primary"
        text-color="white"
        class="text-center py-5"
        body-wrapper
      >
        <CCardBody>
          <h2>{{ $t("Login") }}</h2>
          <p class="text-muted">{{ $t("Login to your Mazars account") }}</p>
          <CCardBody>
            <div
              @click="loginWithOauth"
              class="btn btn-lg btn-secondary px-4 text-center"
            >
              <CSpinner v-if="oauthLoading" />
              <div v-else>
                <img
                  src="/img/ForvisMazars-Logo.png"
                  alt=""
                  style="max-width: 100px"
                />
                <div>
                  {{ $t("Sign in via") }}
                  <br />
                  Mazars The Gate™
                </div>
              </div>
            </div>
          </CCardBody>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import { API_ENDPOINTS } from "@/constants/common";
import { ActiveUser } from "@/services/user";
import { OrganizationsService } from "@/services/organizations";
import { UsersService } from "@/services/users";
import store from "@/store";
import { IS_MZ_BRANDING } from "@/constants/config";
import { AuthService } from "@/services/auth";
import Message from "vue-m-message";
import { useUserStore } from "@/stores/UserStore";
import { useGlobalStore } from "@/stores/store";

export default {
  name: "Login",
  props: ["show_email_login"],
  data: function () {
    return {
      globalStore: useGlobalStore(),
      IS_MZ_BRANDING,
      login: "",
      password: "",
      mzAuthUri: "",
      oauthLoading: false,
      userStore: useUserStore(),
      email: "",
      showFirstTimeLogin: false,
      showForgotPassword: false,
      isLogin: false,
      isLoading: false,
      isSendingResetRequest: false,
    };
  },

  mounted() {
    this.isLoading = false;
    this.redirectAuthenticated();
    this.tryLoginWithAccessCode();
    if (IS_MZ_BRANDING) {
      this.fetchMzAuthUri();
    }
  },

  methods: {
    async handleSubmit(mode) {
      if (mode == "create") {
        const { data, res } = await AuthService.firstTimeLogin({
          email: this.email,
        });
        if (!res.ok) {
          Message.error(data.detail);
        } else {
          const token = data.token;
          Message.success(
            this.$t(
              "User has already been invited to some project. Use form below to set your password."
            )
          );
          this.$router.push({
            name: "Password reset",
            params: { token: token },
          });
          this.showFirstTimeLogin = false;
        }
      } else if (mode == "reset") {
        this.isSendingResetRequest = true;
        const { data, res } = await AuthService.forgotPassword({
          email: this.email,
        });
        if (!res.ok) {
          Message.error("Error resetting password!");
          this.isSendingResetRequest = false;
        } else {
          Message.success("Password reset link was sent to your email.");
          this.email = "";
          this.showForgotPassword = false;
          this.isSendingResetRequest = false;
        }
      }
    },
    async loginWithOauth() {
      await this.fetchMzAuthUri();
      window.location.href = this.mzAuthUri;
    },
    redirectAfterLogin() {
      //const pathToLoadAfterLogin = localStorage.getItem("pathToLoadAfterLogin");
      const pathToLoadAfterLogin = "/home";
      localStorage.removeItem("pathToLoadAfterLogin");
      this.$router.replace(
        (!pathToLoadAfterLogin?.includes("login") && pathToLoadAfterLogin) ||
          "/"
      );
    },
    async redirectAuthenticated() {
      if (ActiveUser.get()) {
        this.isLoading = true;
        const userData = await UsersService.getActiveUserInfo();
        // await this.userStore.getActiveUserInfo();
        if (userData && userData.success) {
          const currentOrg = userData.data.current_organization;
          const currentPrj = currentOrg.projects[0];
          const includedOrgs =
            await OrganizationsService.list_organizations_and_projects();

          function collectLevelObjects(node, levels = []) {
            if (node && Array.isArray(node)) {
              node.forEach((child) => {
                if (child.level_name && child.id) {
                  levels.push({ value: child.ob_id, label: child.level_name });
                }
                if (child.children && Array.isArray(child.children)) {
                  collectLevelObjects(child.children, levels);
                }
              });
            }
            return levels;
          }
          function findSpecificOrgAndProject(orgs, orgId, projectId) {
            const targetOrg = orgs.find((org) => org._id === orgId);
            if (!targetOrg) {
              console.error("Organization not found!");
              return null;
            }
            const targetProject = targetOrg.projects.find(
              (project) => project._id === projectId
            );
            if (!targetProject) {
              console.error("Project not found!");
              return null;
            }
            return { org: targetOrg, project: targetProject };
          }
          const { org, project } = findSpecificOrgAndProject(
            includedOrgs.data.data,
            currentOrg._id,
            currentPrj._id
          );
          const allOrgLevels = collectLevelObjects(org.tree);
          const allProjectLevels = collectLevelObjects(project.tree);
          const allLevelObjects = allOrgLevels.concat(allProjectLevels);
          this.globalStore.setScopeLevelsOptions(allLevelObjects);
          function extractRoleKeys(roleSystem) {
            const roleKeys = [];
            if (roleSystem.front_office) {
              roleKeys.push(...Object.keys(roleSystem.front_office));
            }
            if (roleSystem.back_office) {
              roleKeys.push(...Object.keys(roleSystem.back_office));
            }
            return roleKeys;
          }

          function getRolesForProject(project) {
            const rolesArray = [];
            if (project.role_system) {
              const roleKeys = extractRoleKeys(project.role_system);
              roleKeys.forEach((roleKey) => {
                rolesArray.push({ value: roleKey, label: roleKey });
              });
            }
            return rolesArray;
          }

          const rolesArray = getRolesForProject(project);
          this.globalStore.setScopeRolesOptions(rolesArray);
          const permissions = await UsersService.getPermissions();

          if (userData.data) {
            this.userStore.setBlobToken(userData.data.blob_token);
            if (userData.data.current_role) {
              this.userStore.setCurrentPermissions(userData.data.current_role);
            }
            this.$store.commit("setUserData", userData.data);
            this.$store.commit("setOrg", userData.data.current_organization);
            this.$store.commit("setOrgObj", userData.data.current_organization);
            this.$store.commit(
              "setProject",
              userData.data.current_organization.projects[0]
            );
            this.$store.commit("setJobsPoints", userData.data.level_points_v2);
            const firstEntry = Object.entries(
              userData.data.current_job_description
            )[0];

            let currentLevel = userData.data.current_level;
            if (currentLevel === "") {
              let currentRole = Object.keys(
                userData.data.current_job_description
              )[0];
              currentLevel = userData.data.level_points_v2.map((level) => {
                if (level.job === currentRole) {
                  return level.level;
                }
              })[0];
            }
            this.$store.commit("setJob", firstEntry);

            this.userStore.setCurrentLevel(currentLevel);

            this.userStore.setUserPoints(userData.data.total_points);
            this.userStore.setUserTrainingPoints(
              userData.data.training_total_points
            );
            this.userStore.setUserTrainingProgress(
              userData.data.training_points
            );
            this.userStore.setTeamTrainingPoints(
              userData.data.training_team_points
            );

            const teamPointsData = userData.data.level_points_v2;
            const neededPointsData = teamPointsData.filter((point) => {
              return (
                point.job === firstEntry[0] && point.level === currentLevel
              );
            })[0];
            this.userStore.setTeamPoints(neededPointsData);

            this.userStore.setUsersProgress(userData.data.total_progress);
            this.userStore.setAllRolePoints(userData.data.all_roles_points);
            const teamProgressData = userData.data.level_progress_v2;
            const neededProgressData = teamProgressData.filter((point) => {
              return (
                point.job === firstEntry[0] && point.level === currentLevel
              );
            })[0];

            this.userStore.setUsersTeamProgress(neededProgressData);
          }

          if (includedOrgs.data)
            this.$store.commit("setIncludedOrgs", includedOrgs.data);
          if (permissions.data) {
            this.$store.commit("setIsAdmin", permissions.data.is_admin);
            this.$store.commit("setIsUserAdmin", permissions.data.is_admin);
            this.userStore.setIsAdminUser(permissions.data.is_admin_member);
            if (!permissions.data.is_admin_member) {
              const firstEntry = Object.entries(
                userData.data.current_job_description
              )[0];

              this.$store.commit(
                "setPermissions",
                permissions.data.level_permissions_roles[firstEntry[0]]
              );
            } else {
              this.$store.commit(
                "setPermissions",
                permissions.data.aggregated_permissions
              );
            }
          }
        }

        this.redirectAfterLogin();
        this.isLoading = false;
      }
    },
    handleError() {
      Message.error("Login failed");
    },
    async tryLoginWithAccessCode() {
      const { code, state } = this.$route.query;
      if (code && state) {
        this.oauthLoading = true;
        const { res, data } = await this.$request({
          url: API_ENDPOINTS.mzAuthCallback,
          query: {
            code,
            state,
          },
        });
        if (res.status !== 200) {
          this.oauthLoading = false;
          return this.handleError();
        }
        await ActiveUser.setToken(data.access_token);
        this.redirectAfterLogin();
      }
    },
    async fetchMzAuthUri() {
      const { data } = await request({
        url: API_ENDPOINTS.mzAuth,
      });
      this.mzAuthUri = data.authorization_url;
    },
    async handleLogin() {
      this.isLogin = true;
      const { res } = await AuthService.login({
        email: this.login,
        password: this.password,
      });
      if (res.status !== 200) {
        this.isLogin = false;
        return this.handleError();
      }
      this.$store.commit("setLoginStatus", true);
      this.isLogin = false;
      this.redirectAuthenticated();
    },
  },
};
</script>

<style scoped>
.btn_container {
  display: flex;
  justify-content: end;
}
.mz-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
}

.custom-card {
  width: 495px;
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  max-width: 100%;
  border: 1px solid rgb(195, 195, 195);
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.slider {
  right: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  padding: 13.3px;
  overflow: hidden;
  transition: width 0.3s ease;
  border-radius: 10px;
}

.form-heading {
  color: #0c2044;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 32px;
  font-weight: 700;
}
.form-subtext {
  color: #81848a;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.form-label {
  color: #0c2044;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.form-button {
  width: 130px;
  border-radius: 0px 0px 0px 25px;
  margin-top: 20px;
  margin-left: -10px;
}

.back-btn {
  position: absolute;
  bottom: 35px;
  right: 45px;
  padding: 10px;
}

.styled-checkbox {
  fill: #f8f8f8;
  width: 20px;
  height: 20px;
  margin-top: 9px;
}
</style>
